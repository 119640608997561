import { Text, Button, Center } from '@chakra-ui/react'

import { AsteroidSal } from 'gamma_components/AsteroidSal'
import { CenteredMessageCard } from 'gamma_components/CenteredMessage'

export default function Error404Page() {
  return (
    <AsteroidSal>
      <CenteredMessageCard
        lede="Houston, we have a problem..."
        title="You're in uncharted territory."
        stackProps={{ shadow: 'lg-dark', position: 'relative', zIndex: 2 }}
      >
        <Text>
          We searched the whole Gammaverse, but we couldn't find that page.
        </Text>
        <Center>
          <Button
            variant="solid"
            size="lg"
            mt={6}
            onClick={() => {
              window.location.href = '/'
            }}
          >
            Take me back to safety
          </Button>
        </Center>
      </CenteredMessageCard>
    </AsteroidSal>
  )
}
