import { Box, Flex, BoxProps } from '@chakra-ui/react'
import { gammaTheme } from '@gamma-app/ui'
import { useEffect, useRef, PropsWithChildren } from 'react'

import asteriodImg from 'publicImages/asteriod.svg'
import salTetherImg from 'publicImages/Sal-Tether.svg'
import { initializeStarfieldCanvas } from 'utils/animations'

const backgroundColor = gammaTheme.colors.trueblue[900] as string

export const AsteroidSal = ({ children }: PropsWithChildren<{}>) => {
  const canvas = useRef(null)

  useEffect(() => {
    if (!canvas.current) {
      return
    }
    return initializeStarfieldCanvas(canvas.current, 1000, backgroundColor)
  }, [])

  const asteroidBaseProps: BoxProps = {
    bgImage: asteriodImg.src,
    pos: 'absolute',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    animation: 'rotation-asteroid 200s infinite linear',
    pointerEvents: 'none',
  }

  return (
    <Flex
      w="100vw"
      h="100vh"
      bg={{
        base: backgroundColor,
        // black to  'portal space view' effect when window is larger
        '2xl': 'black',
      }}
      overflow="auto"
      zIndex={2}
      transitionProperty="background-color"
      transitionDuration="1s"
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        flex="1"
        p={[4, 16]}
        pos="relative"
        bg={backgroundColor}
        maxW="8xl"
        maxH="5xl"
        minH={0}
        left="50%"
        top="50%"
        transform="translate(-50%,-50%)"
        borderRadius={{ base: 0, xl: '100px' }}
        overflow="hidden"
        transitionProperty="all"
        transitionDuration="1s"
      >
        {/* Asteroids */}
        <Box
          {...asteroidBaseProps}
          w="15vw"
          h="280px"
          left="12vw"
          top={['5vh', '10vh']}
          maxW="100px"
          zIndex={1}
          transitionProperty="all"
          transitionDuration="normal"
        />
        <Box
          {...asteroidBaseProps}
          w="15vw"
          h="280px"
          left="5vw"
          top="25vh"
          maxW="140px"
        />
        <Box
          {...asteroidBaseProps}
          w="20vw"
          h="280px"
          right={'calc(6vw - 50px)'}
          top="5vh"
          maxW="180px"
        />
        <Box
          {...asteroidBaseProps}
          w="35vw"
          h="415px"
          left="3vw"
          bottom={0}
          maxW="415px"
        />
        {/* Sal */}
        <Box
          bgImage={salTetherImg.src}
          w="100%"
          h="100%"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          backgroundPosition="0px 10%"
          position="absolute"
          right="-20px"
          top={0}
          zIndex={0}
          maxW="5xl"
          animation="jiggle-sal 30s infinite alternate-reverse"
          pointerEvents="none"
        />
        {children}
        <Box
          ref={canvas}
          as="canvas"
          w="100vw"
          h="100vh"
          id="starfield"
          pos="absolute"
          zIndex={-1}
        />
      </Flex>
    </Flex>
  )
}
