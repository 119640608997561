/**
 * For creating a star field canvas
 * Source: https://betterprogramming.pub/fun-with-html-canvas-lets-create-a-star-field-a46b0fed5002
 */

export function initializeStarfieldCanvas(
  canvas: HTMLCanvasElement,
  starCount = 500,
  backgroundColor = 'black'
): () => void {
  const context = canvas.getContext('2d')
  if (typeof window === undefined || !context) {
    return () => {}
  }
  let enabled = true

  let w: number
  let h: number

  const setCanvasExtents = () => {
    w = document.body.clientWidth
    h = document.body.clientHeight
    canvas.width = w
    canvas.height = h
  }

  setCanvasExtents()

  window.onresize = () => {
    setCanvasExtents()
  }

  const makeStars = (count: number) => {
    const out: { x: number; y: number; z: number }[] = []
    for (let i = 0; i < count; i++) {
      const s = {
        x: Math.random() * 1600 - 800,
        y: Math.random() * 900 - 450,
        z: Math.random() * 1000,
      }
      out.push(s)
    }
    return out
  }

  const stars = makeStars(starCount)

  const clear = () => {
    context.fillStyle = backgroundColor
    context.fillRect(0, 0, canvas.width, canvas.height)
  }

  const putPixel = (x: number, y: number, brightness: number) => {
    const intensity = brightness * 255
    const rgb = `rgb(${intensity},${intensity},${intensity})`
    context.fillStyle = rgb
    context.beginPath()
    context.arc(x, y, 1, 0, 2 * Math.PI)
    context.closePath()
    context.fill()
  }

  const moveStars = (distance: number) => {
    for (let i = 0; i < stars.length; i++) {
      const s = stars[i]
      s.z -= distance
      while (s.z <= 1) {
        s.z += 1000
      }
    }
  }

  let prevTime: number
  const init = (time: number) => {
    prevTime = time
    requestAnimationFrame(tick)
  }

  const tick = (time: number) => {
    if (!enabled) {
      return
    }
    const elapsed = time - prevTime
    prevTime = time

    moveStars(elapsed * 0.1)

    clear()

    const cx = w / 2
    const cy = h / 2

    for (let i = 0; i < stars.length; i++) {
      const star = stars[i]

      const x = cx + star.x / (star.z * 0.001)
      const y = cy + star.y / (star.z * 0.001)

      if (x < 0 || x >= w || y < 0 || y >= h) {
        continue
      }

      const d = star.z / 1000.0
      const b = 1 - d * d

      putPixel(x, y, b)
    }

    requestAnimationFrame(tick)
  }

  requestAnimationFrame(init)
  return () => {
    enabled = false
  }
}
